import binkySrc2, {
  AuthenticatedUser,
  LinkedUserAccountList,
  MIGRATION_TYPE,
  OrganizationList,
  authentication,
  feature,
  findAnyUserAccount,
  getUserAccount,
  log,
} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';

import {getPathWithoutProtocolAndDomain} from 'common/api/httpUtils';
import rootStore from 'core/RootStore';
import {useAuthentication} from 'core/providers/authentication/AuthenticationProvider';
import {getActiveUserAccount} from 'core/services/organization/activeOrganizationUtils';

import MigrationBumper from '../../errorComponents/MigrationBumper';
import UnauthorizedBumper from '../../errorComponents/UnauthorizedBumper';
import {useAppLoadingOverlay} from '../AppLoadingOverlayProvider';
import {useFeature} from '../features/FeatureProvider';

const OrganizationContext = createContext();

/**
 * Provides organization data to its children components.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children components.
 * @returns {React.ReactNode} The wrapped children components.
 */

const OrganizationsProvider = ({children}) => {
  const {authenticationLoaded} = useAuthentication();

  const {featureFlagsLoaded} = useFeature();
  const [organization, setOrganization] = useState(null);
  const [organizationsLoaded, setOrganizationsLoaded] = useState(false);
  const [showUserUnauthorized, setShowUserUnauthorized] = useState(false);
  const [showMigrationBumper, setShowMigrationBumper] = useState(false);
  const [migrationType, setMigrationType] = useState(null);
  const handleError = useErrorHandler();
  const {hideLoadingOverlay} = useAppLoadingOverlay();
  const ERROR_ORG_NOT_FOUND = 'Error setting active org, org not found in OrganizationList';
  const ERROR_NO_ORGS_AVAILABLE = 'Unauthorized user';

  const getOrganizationsAndLinkedUserAccounts = useCallback(async () => {
    const [organizationList, linkedUserAccountList] = await Promise.all([
      OrganizationList.get(),
      LinkedUserAccountList.get({includePaths: true, userId: AuthenticatedUser.get().getId()}),
    ]);
    return {linkedUserAccountList, organizationList};
  }, []);

  const setActiveOrganizationInModels = useCallback(async (org, organizationList) => {
    if (
      feature.isEnabled('temp_pa_7320_edu_terms_mini_app') &&
      AuthenticatedUser.get().getRoles().isOrgAdminForOrg(org.id)
    ) {
      org.includeTermsAcceptances = true;
      org.fetchTemplateNames = ['edu_coppa_notice', 'edu_student', 'edu_student_not'];
    }
    await rootStore.organizationStore.setActiveOrg(org);
    await organizationList.setActive(org.id);
    const activeOrg = await organizationList.getActive();
    setOrganization(activeOrg);
  }, []);

  const setActiveOrganization = useCallback(
    async (userAccountInfo, organizationList) => {
      try {
        const orgId = userAccountInfo.orgId;
        const org = organizationList.items.find((item) => item.id === orgId);
        if (
          (feature.isEnabled('temp_adobe_agent_access') &&
            AuthenticatedUser.get().getRoles().isActingAsAdobeAgentForOrg(orgId)) ||
          (org && organizationList.has(orgId))
        ) {
          await setActiveOrganizationInModels(org, organizationList);
        } else {
          throw new Error(ERROR_ORG_NOT_FOUND);
        }
      } catch (error) {
        // TODO: handler for DELEGATION_GROUPS_MIGRATION_ERROR and MIGRATING_TO_DELEGATION_GROUPS error in old code
        // not thrown anywhere in the old code therefore we dont need to handle
        // log.error(ERROR_ORG_NOT_FOUND, error);
      }
      setOrganizationsLoaded(true);
    },
    [setActiveOrganizationInModels]
  );

  const updateAgentActiveOrganization = useCallback(async (organizationList, userAccountInfo) => {
    organizationList.items.push(
      new binkySrc2.services.organization.Organization({id: userAccountInfo.orgId})
    );
    await organizationList.setActive(userAccountInfo.orgId);
    AuthenticatedUser.get().getRoles().setIsActingAsAdobeAgentForOrg(userAccountInfo.orgId);
  }, []);

  const ensureOrganizationUserIsActive = useCallback(
    async (organizationList, userAccountInfo) => {
      if (!userAccountInfo) {
        log.error(ERROR_NO_ORGS_AVAILABLE);
        setShowUserUnauthorized(true);
        hideLoadingOverlay();
        throw new Error(ERROR_NO_ORGS_AVAILABLE);
      }
      if (userAccountInfo.actingAsAgent) {
        await updateAgentActiveOrganization(organizationList, userAccountInfo);
      } else if (!userAccountInfo.currentUser) {
        const redirectError = await authentication.switchTo(
          userAccountInfo.userId,
          getPathWithoutProtocolAndDomain()
        );
        return redirectError ? {redirected: false} : {redirected: true};
      }
      return {redirected: false};
    },
    [updateAgentActiveOrganization, hideLoadingOverlay]
  );

  const checkMigrationStatus = useCallback(() => {
    const migrationTypeStates = {
      [MIGRATION_TYPE.ESM_TYPE1]: 'migrating',
      [MIGRATION_TYPE.MA_LEGACY_TO_ADMIN_CONSOLE]: 'legacy-to-ac-migrating',
      [MIGRATION_TYPE.T2E]: 't2e-migrating',
      [MIGRATION_TYPE.VIP2DIRECT]: 'vip2direct-migrating',
      [MIGRATION_TYPE.VIPMP]: 'vipmp-migrating',
    };
    const migrations = rootStore.organizationStore.migrationList;
    Object.keys(migrationTypeStates).forEach((type) => {
      const migration = migrations.findByType(type);
      if (migration.isOrgLocked()) {
        setMigrationType(migrationTypeStates[type]);
        setShowMigrationBumper(true);
        hideLoadingOverlay();
      }
    });
  }, [hideLoadingOverlay]);

  const clearModelCache = () => {
    binkySrc2.models.cache.modelCache.clearAll();
  };

  const getPathOrDefaultOrgId = ({linkedUserAccountList, organizationList}) => {
    // Assume that the user must go to the orgID if it's available from the URL.
    // Thus, find it from orgList first, then from linkedAccounts.
    // Otherwise, reject with ORG_NOT_FOUND.
    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- window.location is used
    const selectedOrgId = window.location.href
      .split('/')
      .find((segment) => segment.endsWith('AdobeOrg'));

    if (selectedOrgId) {
      return getUserAccount({
        linkedUserAccountList,
        organizationList,
        orgId: selectedOrgId,
      });
    }

    // Check stored org ID only with orgList, do not check linkedAccounts.
    // OrgList contains org list accessible with the current token, i.e.
    // token switch is not needed.
    // Checking both orgList and linkedAccount would cause CAUIP-11119 where
    // the user attempted to login to org/profile B and ended up with org A
    // because org A is stored in the session storage.
    const storedOrgId = OrganizationList.getActiveOrgIdFromSessionStorage();
    if (storedOrgId && organizationList.has(storedOrgId)) {
      return getUserAccount({
        linkedUserAccountList,
        organizationList,
        orgId: storedOrgId,
      });
    }

    // There's no org available from the URL or from session storage.
    // Attempt to find any available org from orgList or linkedAccounts
    return findAnyUserAccount({
      linkedUserAccountList,
      organizationList,
    });
  };

  const loadOrganization = useCallback(async () => {
    try {
      clearModelCache();
      const {linkedUserAccountList, organizationList} =
        await getOrganizationsAndLinkedUserAccounts();

      const userAccountInfo = feature.isDisabled('temp_adobe_agent_access')
        ? getPathOrDefaultOrgId({
            linkedUserAccountList,
            organizationList,
          })
        : await getActiveUserAccount({
            linkedUserAccountList,
            organizationList,
          });

      const redirectResult = await ensureOrganizationUserIsActive(
        organizationList,
        userAccountInfo
      );
      if (!redirectResult?.redirected) {
        await setActiveOrganization(userAccountInfo, organizationList);
      }
    } catch (error) {
      log.error('Error loading organization:', error);
      if (error.message === ERROR_NO_ORGS_AVAILABLE || error.message === ERROR_ORG_NOT_FOUND) {
        setOrganizationsLoaded(true);
        return;
      }
      handleError(error);
    }
  }, [
    ensureOrganizationUserIsActive,
    getOrganizationsAndLinkedUserAccounts,
    setActiveOrganization,
    handleError,
  ]);

  // Check org migration status on org change
  useEffect(() => {
    if (!organizationsLoaded || !organization) return;
    checkMigrationStatus();
  }, [organizationsLoaded, checkMigrationStatus, organization]);

  // Load organization data
  useEffect(() => {
    if (!authenticationLoaded || !featureFlagsLoaded) {
      return;
    }
    log.info('Loading organization data...');
    loadOrganization();
  }, [
    authenticationLoaded,
    featureFlagsLoaded,
    loadOrganization,
    getOrganizationsAndLinkedUserAccounts,
  ]);

  const memoizedData = useMemo(
    () => ({organization, organizationsLoaded}),
    [organization, organizationsLoaded]
  );

  if (showUserUnauthorized) {
    return <UnauthorizedBumper />;
  }

  if (showMigrationBumper) {
    return (
      <MigrationBumper
        activeOrg={rootStore.organizationStore.activeOrg?.name}
        migrationType={migrationType}
      />
    );
  }

  return (
    featureFlagsLoaded && (
      <OrganizationContext.Provider value={memoizedData}>{children}</OrganizationContext.Provider>
    )
  );
};

OrganizationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useOrganization = () => useContext(OrganizationContext);

export {OrganizationsProvider, useOrganization};
