import {
  AuthenticatedUser,
  CLOUD,
  LicenseGroupList,
  configStore,
  feature,
  getEarliestStartDate,
  getSeatBasedAssignableLicenseCount,
  getSeatBasedTotalProvisionedQuantity,
  getTrialContract,
  hasEnterpriseProducts,
  hasProductsInCloud,
  hasSDLProducts,
  hasTeamProducts,
} from '@admin-tribe/acsc';
import invokeMap from 'lodash/invokeMap';
import sum from 'lodash/sum';
import {when} from 'mobx';

import rootStore from 'core/RootStore';
import trialHelper from 'core/products/trial-helper/trialHelper';
import AutoAssignRulesCache from 'core/services/product/AutoAssignRulesCache';

import {
  constructAutoAssignRulesSpecialString,
  getIntroductionsData,
  getJitRuleDefaultProfileDefined,
  getNumberOfPendingProductRequests,
  getTypeAndAudienceFromAutoAssignRules,
} from './sharedContextualParamsUtils';

const CONTEXTUAL_PARAMS_SHARED = [
  '1ar', // admin roles
  '1ms', // market segment
  '1pic', // number of pending user introductions
  '1iic', // ignored user introductions
  '1op', // comma separated string of all active org policies
  'aar', // auto assign rules in a special syntax
  'aarc', // auto assign rules count
  'jitRuleAudience',
  'jrdpd', // jit rule default profile defined
  'jitRuleType',
  'licenseAssignmentPercentage',
  'earliestContractStartDate',
  'hasCCSVProducts',
  'hasCreativeCloudProducts',
  'hasDocumentCloudProducts',
  'hasEnterpriseProducts',
  'hasExperienceCloudProducts',
  'hasSDLProducts',
  'hasTeamProducts',
  'pendingRequests',
  'productFamilies',
  'productFamiliesV2',
  'trialContractCountry',
  'trialContractModel',
  'trialContractStatus',
  'trialDaysFromStart',
  'trialDaysSinceEnd',
  'trialDurationDays',
  'trialActiveLicenseCount',
  'trialAvailableLicenseCount',
  'trialLicenseCount',
  'trialOfferIdList',
  'trialProductCodeList',
];

/**
 * List of products that are candidates for auto assign rules campaigns, this is just a workaround to
 * avoid Sophia API 8K limit. This list should be removed once we move to AJO, hopefully.
 * This array can be modified to add or remove products as needed.
 * For more information, please refer to the JIRA ticket: https://jira.corp.adobe.com/browse/ONESIE-43207
 */
const AUTO_ASSIGNMENT_RULE_CANDIDATE_PRODUCTS = [
  'APAP',
  'APCC',
  'APAS',
  'APPS',
  'ASIG',
  'APPP',
  'APSF',
  'ACRO',
  'ACRS',
  'ACAI',
];

/**
 * Fetches params that are common between gainsight/sophia
 */
class SharedContextualParams {
  /**
   * @description instantiates a new instance of SharedContextualParams.
   * @returns {Promise<SharedContextualParams>} SharedContextualParams model object.
   */
  static get() {
    const model = new SharedContextualParams();
    return model.refresh();
  }

  async fetchAndAssignContracts() {
    await when(() => !!rootStore.organizationStore.contractList);
    const contractList = rootStore.organizationStore.contractList;

    Object.assign(this, {
      earliestContractStartDate: getEarliestStartDate(contractList),
    });

    return this;
  }

  async fetchAndAssignOther() {
    await when(() => !!rootStore.organizationStore.productList);
    await when(() => !!rootStore.organizationStore.activeOrgId);
    await when(() => !!rootStore.organizationStore.globalAdminPolicyList);
    const globalAdminPolicyList = rootStore.organizationStore.globalAdminPolicyList;

    const adminRoles = AuthenticatedUser.get()
      .getRoles()
      .roles.filter((role) => role.organization === rootStore.organizationStore.activeOrgId)
      .map((role) => role.named_role)
      .join(',');
    const {ignoredIntroductionsCount, pendingIntroductionsCount} = await getIntroductionsData();
    const activePolicies = globalAdminPolicyList.items
      .filter((policy) => !!globalAdminPolicyList.getPolicyValue(policy.name))
      .map((policy) => policy.name);
    const autoAssignRules = AutoAssignRulesCache.get()?.autoAssignRules;
    const [jitRuleAudience, jitRuleType] = getTypeAndAudienceFromAutoAssignRules(autoAssignRules);

    const orgId = rootStore.organizationStore.activeOrgId;
    const candidateProducts = rootStore.organizationStore.productList.items.filter((product) =>
      AUTO_ASSIGNMENT_RULE_CANDIDATE_PRODUCTS.includes(product.code)
    );
    const promises = candidateProducts.map((product) =>
      LicenseGroupList.get({
        orgId,
        product,
      })
    );
    const candidateProductsLicenseGroups = await Promise.all(promises);
    const jitRuleDefaultProfileDefined = getJitRuleDefaultProfileDefined(
      autoAssignRules,
      candidateProductsLicenseGroups
    );

    Object.assign(this, {
      '1ar': adminRoles,
      '1iic': ignoredIntroductionsCount,
      '1ms': rootStore.organizationStore.activeOrg.marketSegment,
      '1op': activePolicies.join(','),
      '1pic': pendingIntroductionsCount,
      jitRuleAudience,
      jitRuleType,
      jrdpd: feature.isEnabled('temp_tno_overview_hva_banner_modal')
        ? jitRuleDefaultProfileDefined
        : undefined,
    });
  }

  async fetchAndAssignProducts() {
    await when(() => !!rootStore.organizationStore.productList);
    await when(() => !!rootStore.organizationStore.activeOrgId);
    await when(() => configStore.whenConfigLoaded());

    const productList = rootStore.organizationStore.productList;
    const licenseTotal = getSeatBasedAssignableLicenseCount(productList);
    const licensesAssigned = getSeatBasedTotalProvisionedQuantity(productList);
    const licenseAssignmentPercentage = licenseTotal
      ? (licensesAssigned / licenseTotal) * 100
      : null;

    const autoAssignRules = AutoAssignRulesCache.get()?.autoAssignRules;

    Object.assign(this, {
      aar: constructAutoAssignRulesSpecialString(autoAssignRules, productList),
      aarc: autoAssignRules?.length,
      hasCCSVProducts: productList.items.some((product) => product.code === 'CCSV'),
      hasCreativeCloudProducts: hasProductsInCloud(productList, CLOUD.CREATIVE),
      hasDocumentCloudProducts: hasProductsInCloud(productList, CLOUD.DOCUMENT),
      hasEnterpriseProducts: hasEnterpriseProducts(productList),
      hasExperienceCloudProducts: hasProductsInCloud(productList, CLOUD.EXPERIENCE),
      hasSDLProducts: hasSDLProducts(productList),
      hasTeamProducts: hasTeamProducts(productList),
      licenseAssignmentPercentage,
      pendingRequests: await getNumberOfPendingProductRequests(),
      productFamilies: productList.items.map((item) => item.family),
    });

    return this;
  }

  async fetchAndAssignTrials() {
    await when(() => !!rootStore.organizationStore.contractList);
    await when(() => !!rootStore.organizationStore.productList);

    const contractList = rootStore.organizationStore.contractList;
    const productList = rootStore.organizationStore.productList;

    const trialContract = getTrialContract(contractList);
    const trialProducts = productList.items.filter((product) =>
      trialHelper.isTrialProduct(product)
    );

    // If trialContract is undefined, this will return 0.
    const trialDurationDays = trialHelper.getTrialDuration(trialContract);

    Object.assign(this, {
      trialContractCountry: trialContract?.ownerInfo?.countryCode ?? '',
      trialContractModel: trialContract?.model ?? '',
      trialContractStatus: trialContract?.status ?? '',
      trialDurationDays,
    });

    if (trialContract) {
      const daysLeft = trialContract.getDaysLeft();
      if (trialContract.isStatusActive()) {
        this.trialDaysFromStart = trialDurationDays - daysLeft;
      } else if (trialContract.isStatusExpired()) {
        this.trialDaysSinceEnd = -daysLeft;
      }
    }

    // eslint-disable-next-line lodash/chaining -- cannot chain
    const assignableLicenseCount = sum(invokeMap(trialProducts, 'getAssignableLicenseCount'));
    const activeCount = sum(trialProducts.map((product) => product.provisionedQuantity));

    Object.assign(this, {
      trialActiveLicenseCount: activeCount,
      trialAvailableLicenseCount: assignableLicenseCount - activeCount,
      trialLicenseCount: assignableLicenseCount,
      trialOfferIdList: [...new Set(trialProducts.map((product) => product.offerId))],
      trialProductCodeList: [...new Set(trialProducts.map((product) => product.code))],
    });
  }

  async refresh() {
    const tasks = [
      this.fetchAndAssignContracts(),
      this.fetchAndAssignProducts(),
      this.fetchAndAssignTrials(),
    ];

    if (feature.isEnabled('temp_sophia_introductions')) {
      tasks.push(this.fetchAndAssignOther());
    }

    await Promise.all(tasks);

    return this;
  }
}

export {CONTEXTUAL_PARAMS_SHARED};
export default SharedContextualParams;
