import {log} from '@admin-tribe/acsc';
import {Provider, defaultTheme} from '@adobe/react-spectrum';
import {useAccessRequestList, useAccessRequestPut} from '@pandora/data-model-acrs';
import {REQUEST_ACCESS_STATUS} from '@pandora/react-data-source-acrs';
import {PageWait} from '@pandora/react-page-wait';
import React, {useCallback, useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import {useLocation, useParams} from 'react-router-dom';

import {
  PREVIOUSLY_APPROVED_TOAST_ID,
  RA_TOAST_QUERY_PARAM,
  SUCCESS_TOAST_ID,
} from 'features/approve-product/utils/productApproveConstants';
import {redirectToProductReviewPage} from 'features/approve-product/utils/productApproveRedirect';
/**
 * Handles immediate product approval request.
 */
const ProductApprovePage = () => {
  const {orgId, appAuthRequestId} = useParams();
  const location = useLocation();
  const {data} = useAccessRequestList({orgId});
  // The source here is set to EMAIL, as this page is only accessed via links sent via email.
  const approvalSource = 'EMAIL';
  const accessRequestPut = useAccessRequestPut({orgId, params: {source: approvalSource}});
  const userId = useRef(null);
  const hasEffectRun = useRef(false);
  const intl = useIntl();

  const callACRSRequestApprovalApi = useCallback(
    (productId, productProfile, pendingRequest) => {
      userId.current = pendingRequest.userAccountId;
      pendingRequest.licenseId = productId;
      pendingRequest.productProfileId = productProfile;
      pendingRequest.status = REQUEST_ACCESS_STATUS.APPROVED;

      return accessRequestPut(pendingRequest);
    },
    [accessRequestPut]
  );

  function requestHasStatus(pendingRequest, status) {
    return pendingRequest && pendingRequest.status === status;
  }

  /**
   * Check data and call the ACRS API to approve the request.
   */
  useEffect(() => {
    const approveRequest = async () => {
      if (!hasEffectRun.current && data) {
        hasEffectRun.current = true;
        const queryParams = new URLSearchParams(location.search);
        const productId = queryParams.get('licenseId');
        const productProfile = queryParams.get('productProfileId');
        let alreadyApproved = false;
        let approvedRequest = null;
        try {
          const pendingRequest = data.items.find(
            (item) => item.appAuthRequestId === appAuthRequestId
          );

          alreadyApproved = requestHasStatus(pendingRequest, REQUEST_ACCESS_STATUS.APPROVED);
          if (requestHasStatus(pendingRequest, REQUEST_ACCESS_STATUS.PENDING)) {
            approvedRequest = await callACRSRequestApprovalApi(
              productId,
              productProfile,
              pendingRequest
            );
          }
        } catch (error) {
          log.info('Request Failed, redirecting to review page', error);
        }

        if (requestHasStatus(approvedRequest, REQUEST_ACCESS_STATUS.APPROVED)) {
          log.info('Request Approved, redirecting to homepage');
          redirectToProductReviewPage(orgId, null, RA_TOAST_QUERY_PARAM, SUCCESS_TOAST_ID);
        } else if (alreadyApproved) {
          log.info('Request already approved, redirecting to homepage');
          redirectToProductReviewPage(
            orgId,
            null,
            RA_TOAST_QUERY_PARAM,
            PREVIOUSLY_APPROVED_TOAST_ID
          );
        } else if (userId.current !== null && userId.current !== undefined) {
          log.info('Request Failed, redirecting to review page');
          redirectToProductReviewPage(orgId, userId.current, null, null);
        } else {
          redirectToProductReviewPage(orgId, null, null, null);
        }
      } else {
        log.info('No product requests found');
      }
    };

    approveRequest();
  }, [data, callACRSRequestApprovalApi, location.search, orgId, appAuthRequestId]);

  const props = {
    ariaLabel: `${intl.formatMessage({id: 'common.requestAccess.ariaLabel.pageLoading'})}...`,
    delay: '0',
    message: '',
  };

  return (
    <Provider theme={defaultTheme}>
      {' '}
      <PageWait {...props} />
    </Provider>
  );
};
export default ProductApprovePage;
