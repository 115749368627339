import {feature} from '@admin-tribe/acsc';
import {MemberListTable, OverlayWait} from '@admin-tribe/acsc-ui';
import {PAGE_SIZE_OPTIONS} from '@pandora/react-pagination';
import {
  SELECTION_MODE,
  TableActions,
  TableFilters,
  TableSection,
} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {cloneElement} from 'react';
import {useIntl} from 'react-intl';

import UsersDetailsDrawer from 'common/components/users-details-drawer/UsersDetailsDrawer';
import {useMemberListContext} from 'common/hooks/member-list-context/MemberListContext';
import updateMemberStore from 'common/stores/member-store/updateMemberStore';
import {
  getMemberDetailsHref,
  getTableMsgSubkey,
  isSelectable,
} from 'common/utils/member-utils/memberUtils';
import rootStore from 'core/RootStore';
import {isOrgAdmin, isReadOnly} from 'core/organizations/access/organizationAccess';
import {canViewUserDetails} from 'core/user/access/userAccess';
import SearchForUsersContextualHelp from 'features/users/components/search-for-users-contextual-help/SearchForUsersContextualHelp';
import SearchForUsersMessage from 'features/users/components/search-for-users-message/SearchForUsersMessage';
import {
  goToUserDetails,
  goToUserGroupDetails,
} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

import UserProvisioningStatus from '../products-detail-section/UserProvisioningStatus';

import UserListTableActions from './UserListTableActions';

const deriveSyncIconActionButtonTooltipText = (contextValue, intl, ff) => {
  if (contextValue) {
    return contextValue;
  }
  return ff.isEnabled('temp_domains_externally_managed_account_label')
    ? intl.formatMessage({id: 'settings.domainsTable.externallyManagedByAccountLabel'})
    : intl.formatMessage({id: 'settings.domainsTable.externallyManagedLabel'});
};

/**
 * A table to display a JIL user list.
 * This should be wrapped in a MemberListContextProvider.
 */
const UserListTableSection = ({pageSizeOptions = PAGE_SIZE_OPTIONS.DEFAULT, ...tableProps}) => {
  const {
    canRemoveMember,
    hasSearch,
    loadingMessage,
    memberListDisplay,
    pageContext,
    productIconList,
    store,
    syncIconActionButtonTooltipText,
  } = useMemberListContext();
  const intl = useIntl();
  const msgSubkey = getTableMsgSubkey({pageContext});
  const selectionMode =
    canRemoveMember && !isReadOnly() ? SELECTION_MODE.MULTIPLE : SELECTION_MODE.NONE;

  const product = rootStore.organizationStore.productList.items.find(
    (item) => item.id === store.productId
  );

  // use the context value first, or fallback to the locale value
  const syncIconTooltipText = deriveSyncIconActionButtonTooltipText(
    syncIconActionButtonTooltipText,
    intl,
    feature
  );

  const getProvisioningStatusHandler = (provisioningStatus) => (
    <UserProvisioningStatus
      product={product}
      provisioningStatus={provisioningStatus}
      showEmDashWhenNoStatus
    />
  );

  // When admin wants to see more products, go to members detail page - either User group or User.
  const onClickMore = (member) =>
    member.getType().isUserGroup()
      ? goToUserGroupDetails({userGroupId: member.id})
      : goToUserDetails({userId: member.id});

  // If in searchOnlyContentMode and we're showing the SearchForUsersMessage, we override the TableFilter's built-in isDisabled
  // check so that the search field is enabled.
  const getIsFilterDisabled = () => (store.showSearchForUsersMessage() ? false : undefined);

  // This callback may be called with an item that no longer exists so member may be undefined.
  const getIsItemSelectable = (itemKey) =>
    isSelectable({member: store.getItem(itemKey), pageContext});

  return (
    <OverlayWait
      isLoading={store.isLoading}
      loadingMessage={loadingMessage}
      showContent={!loadingMessage}
      size="M"
    >
      <TableSection
        getIsItemSelectable={getIsItemSelectable}
        getItemKey={(item) => item.id}
        isServerError={store.hasLoadingError}
        items={store.currentItems}
        onTableSectionChange={(params) => updateMemberStore({...params, store})}
        pageNumber={store.pageNumber}
        pageSizeOptions={pageSizeOptions}
        selectionMode={selectionMode}
        totalPages={store.totalPages}
      >
        <TableFilters
          /* contextualHelp passed down to Pandora Search and then to RS SearchField */
          contextualHelp={
            hasSearch && (
              <SearchForUsersContextualHelp isSearchOnlyContentMode={store.searchOnlyContentMode} />
            )
          }
          hasSearch={hasSearch}
          isDisabled={getIsFilterDisabled()}
          label={intl.formatMessage({id: 'common.userListTableSection.search'})}
        />

        <TableActions>
          <UserListTableActions />
        </TableActions>
        {!store.showSearchForUsersMessage() && (store.currentItems || store.hasLoadingError) && (
          <MemberListTable
            aria-label={intl.formatMessage({
              id: `common.userListTableSection.table.${msgSubkey}.ariaLabel`,
            })}
            canViewMemberDetails={canViewUserDetails}
            display={memberListDisplay}
            drawerComponent={<UsersDetailsDrawer />}
            getDisplayNameHref={(member) => getMemberDetailsHref({member, pageContext})}
            getProvisioningStatusHandler={getProvisioningStatusHandler}
            isSystemAdmin={isOrgAdmin()}
            noItemsFoundContentMessage={intl.formatMessage({
              id: `common.userListTableSection.table.${msgSubkey}.noItemsFoundContentMessage`,
            })}
            noSearchResultsContentMessage={
              hasSearch &&
              intl.formatMessage({
                id: `common.userListTableSection.table.${msgSubkey}.noSearchResultsContentMessage`,
              })
            }
            productIconList={productIconList && cloneElement(productIconList, {onClickMore})}
            syncIconActionButtonTooltipText={syncIconTooltipText}
            {...tableProps}
          />
        )}
      </TableSection>
      {/* TableView emptyState does not allow keyboard access to inputs in IllustratedMessage. */}
      {store.showSearchForUsersMessage() && (
        <SearchForUsersMessage onPressLink={() => store.showUsers()} />
      )}
    </OverlayWait>
  );
};

UserListTableSection.propTypes = {
  ...MemberListTable.propTypes,

  /**
   * The pageSizeOptions to use for the user table.
   */
  pageSizeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      /** True if it is the default page size. */
      isDefault: PropTypes.bool,
      /** The string displayed. */
      label: PropTypes.string.isRequired,
    })
  ),
};

// This syntax necessary to work around React 16 bug with displayName.
// ref: https://github.com/facebook/react/issues/18026
// ref: https://mobx.js.org/react-integration.html#displayname
export default observer(UserListTableSection);
