// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************

import {Link} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import {getDevConsoleEndpointUrl} from 'common/utils/devConsoleUtils';

/**
 * A link to the Adobe I/O Console.
 * @param linkText the link text
 * @returns {Element} A Link to the Adobe I/O Console.
 * @constructor
 */
const DevConsoleLink = ({linkText}) => {
  const devConsoleUrl = getDevConsoleEndpointUrl();
  const href = `${devConsoleUrl}/projects`;
  return (
    <Link nowrap={false}>
      <a href={href} rel="noreferrer" target="_blank">
        {linkText}
      </a>
    </Link>
  );
};

DevConsoleLink.propTypes = {
  /**
   * The product we're displaying profiles (aka license groups) for.
   */
  linkText: PropTypes.string.isRequired,
};

export default DevConsoleLink;
