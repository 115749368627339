// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Heading,
  Link,
} from '@adobe/react-spectrum';
import SafeHtml from '@pandora/react-safe-html';
import React from 'react';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

const TermsAndConditionsDialog = (termsHeading, termsAndConditions, close) => (
  <Dialog maxWidth="50rem" size="XL">
    <Heading data-testid="one-console-promo-terms-heading">{termsHeading}</Heading>
    {termsHeading && <Divider />}
    <Content>
      <SafeHtml data-testid="one-console-promo-terms-content" html={termsAndConditions} />
    </Content>
    <ButtonGroup>
      <Button data-testid="one-console-promo-terms-close" onPress={close} variant="secondary">
        Close
      </Button>
    </ButtonGroup>
  </Dialog>
);

/**
 * Renders a link/info icon with terms and conditions popover
 * If the text is passed as props it will render as a link and the popover trigger will be 'click'
 */
const SophiaPromoTermsAndConditionsDialogTrigger = () => {
  const {payload} = useJsonPayload();

  if (!payload || !payload.termsAndConditions || !payload.termsHeading) {
    return null;
  }

  return (
    <DialogTrigger
      data-testid="one-console-promo-terms-dialog-trigger"
      placement="top"
      type="modal"
    >
      <Link
        data-testid="one-console-promo-terms-trigger-link"
        UNSAFE_style={{
          fontSize: 'var(--spectrum-global-dimension-font-size-100)',
          marginLeft: '0',
          textDecoration: 'underline',
        }}
      >
        {payload.termsLabel}
      </Link>

      {(close) => TermsAndConditionsDialog(payload.termsHeading, payload.termsAndConditions, close)}
    </DialogTrigger>
  );
};

SophiaPromoTermsAndConditionsDialogTrigger.propTypes = {};

export default SophiaPromoTermsAndConditionsDialogTrigger;
