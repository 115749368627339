// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************

import {configStore, feature} from '@admin-tribe/acsc';

/**
 * @description Returns the URL for the Adobe Developer Console for the purpose of linking to the console UI.
 * @returns {String} The URL for the Adobe Developer Console.
 */
const getDevConsoleEndpointUrl = () => {
  if (feature.isDisabled('temp_devconsole_ui_separation')) {
    // We have updated the configStore setting for adobeioConsole from console(-stage).adobe.io
    // to developers(-stage).adobe.io, because this is needed for the API to work, but it breaks
    // the UI links, so this mutates the URL back to the old value.
    return configStore.get('services.adobeioConsole')?.url?.replace('developers', 'console');
  }

  return configStore.get('services.devConsoleUI')?.url;
};

// eslint-disable-next-line import/prefer-default-export -- disabling default export
export {getDevConsoleEndpointUrl};
