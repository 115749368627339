import {
  ACTIONBLOCK_ID,
  CAMPAIGN_ID,
  CONTAINER_ID,
  CONTROL_GROUP_ID,
  SURFACE_ID,
  TREATMENT_ID,
  VARIATION_ID,
} from '@admin-tribe/acsc';
import pick from 'lodash/pick';

class HvaCard {
  static get() {
    throw new Error('not implemented!');
  }

  /**
   * @class HvaCard
   * @description Creates a new HVA (High Value Action) Card for use.
   *
   * @param {Object} options Initialization Object (params described below)
   * @param {String} [options.actionBlockId] id of the action block
   * @param {String} [options.campaignId] id of the campaign
   * @param {String} [options.containerId] id of the container
   * @param {String} [options.controlGroupId] id of the control group
   * @param {String} options.ctaLabel The CTA button's translated label
   * @param {String} options.description The translated description of the HVA card
   * @param {Boolean} [options.dismissOnCTA] If this is set to true, the card will be dismissed on CTA click
   * @param {Boolean} [options.dismissOnCTATemp] If this is set to true and dismissOnCTA = true, the card will be dismissed on CTA click without adding the card ID to local storage.
   * This allows for the card to be removed from the current view but will allow the card to re-render on the next page load.
   * @param {String} options.header The translated header of the HVA card
   * @param {String} options.id The id of the HVA card
   * @param {Boolean} [options.isDismissible] The boolean value to determine if the HVA card is dismissible or not. It will render an x-close icon on the HVA card
   * when the value is set to true
   * @param {String} [options.learnMoreHref] Href for "Learn More" link
   * @param {String} [options.goUrl] goUrl for the "Learn More" link
   * @param {String} [options.gainsightTour] id for the gainsightTour trigger link
   * @param {Object} [options.modalConfig] Options for modal to open on CTA, see below
   * @param {Object} [options.modalConfig.closeProp] The modal's prop called whenever the modal is closed
   * @param {Object} [options.modalConfig.Component] The modal Component
   * @param {Object} [options.modalConfig.props] Props to pass to the modal
   * @param {Function} [options.onCTA] Callback function that is triggered when the button is clicked
   * @param {Number} options.priorityOrder The priority of the HVA. Lower numbers are shown first
   * @param {String} options.sectionTitle The translated title for the HVA section on the overview page
   * @param {String} [options.surfaceId] id of the surface
   * @param {Object} [options.icon] Options for passing an icon to render - see below
   * @param {Object} [options.icon.Component] A React component to render as the icon
   * @param {Object} [options.icon.props] If passed a component to render as an icon, pass these props to the component
   * @param {Object} [options.icon.src] The link to the icon
   * @param {Object} [options.icon.style] CSS styling for icon (width, height etc)
   * @param {Object} [options.intlValues] Values to resolve variables in intl.formatMessage()
   * @param {HVA_TYPE} options.type The type of HVA
   * @param {String} [options.treatmentId] id of the treatment
   * @param {String} [options.variationId] id of the variation
   */
  constructor(options) {
    Object.assign(
      this,
      {
        ctaLabel: options.ctaLabel,
        description: options.description,
        dismissOnCTA: options.dismissOnCTA,
        dismissOnCTATemp: options.dismissOnCTATemp,
        gainsightTour: options.gainsightTour,
        goUrl: options.goUrl,
        header: options.header,
        icon: {props: {}, style: {}, ...options.icon},
        id: options.id,
        intlValues: {...options.intlValues},
        isDismissible: options.isDismissible,
        learnMoreHref: options.learnMoreHref,
        modalConfig: {props: {}, ...options.modalConfig},
        onCTA: options.onCTA,
        priorityOrder: options.priorityOrder,
        sectionTitle: options.sectionTitle,
        type: options.type,
      },
      pick(options, [
        ACTIONBLOCK_ID,
        CAMPAIGN_ID,
        CONTAINER_ID,
        CONTROL_GROUP_ID,
        SURFACE_ID,
        TREATMENT_ID,
        VARIATION_ID,
      ])
    );
  }

  isValid() {
    const requiredFields = [
      'ctaLabel',
      'description',
      'header',
      'id',
      'priorityOrder',
      'sectionTitle',
    ];
    return requiredFields.every((requiredField) => !!this[requiredField]);
  }
}

export default HvaCard;
