import {Button, Text} from '@adobe/react-spectrum';
import ShoppingCartIcon from '@spectrum-icons/workflow/ShoppingCart';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import useAddProductModal from 'common/hooks/useAddProductModal';
import chatProvider from 'core/services/chat/chatProvider';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';

// The 'Buy more' button, and the associated modal to add new products on Product page or Account tabs.
const BuyMoreButton = ({contractId, eventName = 'addProducts'}) => {
  const {closeAddProductModal, openAddProducts, showAddProductModal} = useAddProductModal({
    eventName,
  });

  return (
    <>
      <Button data-testid="buy-more-button" onPress={() => openAddProducts()} variant="accent">
        <ShoppingCartIcon />
        <Text>
          <FormattedMessage id="products.allProducts.actions.buyMore" />
        </Text>
      </Button>
      {showAddProductModal && (
        <AddProductModalWrapper
          chat={chatProvider}
          contractId={contractId}
          onClose={() => closeAddProductModal()}
        />
      )}
    </>
  );
};

BuyMoreButton.propTypes = {
  /**
   * Optional contractId to be passed to AddProductModalWrapper component
   */
  contractId: PropTypes.string,

  /**
   * Optional (on-click) eventName to be passed to AddProductModalWrapper component
   * If not passed, the default eventName will be 'addProducts'
   */
  eventName: PropTypes.string,
};

export default BuyMoreButton;
