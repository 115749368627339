import {feature, log, modelCache} from '@admin-tribe/acsc';
import isEmpty from 'lodash/isEmpty';

import adobeioConsoleIntegrations from 'common/api/adobeio-console/adobeioConsoleIntegrations';
import ApiOrganizationList from 'core/api-organization/ApiOrganizationList';

import ApiIntegration from './ApiIntegration';

const API_INTEGRATION_LIST_CACHE_ID = 'ApiIntegrationList';

/**
 * The list of adobeio integrations for the specified Renga org.
 */
class ApiIntegrationList {
  /**
   * @description Method to retrieve an existing list of organization users
   *              from the back-end.
   *
   * @param {Object} options - Options for the constructor
   * @param {String} options.rengaOrgId - the Renga org id
   * @param {String[]} options.techAccountIdsToSearch - the list of tech account ids to search for
   * @returns {Promise<ApiIntegrationList>} Promise that resolves to the instance when the refresh is successful
   */
  static get({rengaOrgId, techAccountIdsToSearch}) {
    const model = new ApiIntegrationList({rengaOrgId, techAccountIdsToSearch});
    const cacheKey = model.getModelKey();

    const cachedPromise = modelCache.get(API_INTEGRATION_LIST_CACHE_ID, cacheKey);
    if (cachedPromise) {
      return cachedPromise;
    }

    // Item is not already cached.
    const promise = model.refresh();
    modelCache.put(API_INTEGRATION_LIST_CACHE_ID, cacheKey, promise);
    return promise;
  }

  /**
   * @description Constructor for ApiIntegrationList model .
   */
  constructor({rengaOrgId, techAccountIdsToSearch}) {
    this.adobeioOrgId = undefined;
    this.rengaOrgId = rengaOrgId;
    this.techAccountIdsToSearch = techAccountIdsToSearch;
    this.items = [];

    // OrganizationIntegrationList and LicenseGroupIntegrationList will call this store
    // with the ids of the members they have fetched for the current page. For current
    // behaviour to be maintained before this new logic is available on production we
    // need a means of disabling the search functionality.
    if (feature.isDisabled('temp_devconsole_page_optimization')) {
      this.techAccountIdsToSearch = undefined;
    }
  }

  getModelKey() {
    let result = this.rengaOrgId;
    if (this.techAccountIdsToSearch) {
      result += `:${this.techAccountIdsToSearch.sort().join(',')}`;
    }
    return result;
  }

  /**
   * @description Method to refresh the contents of the list.
   * @returns {Promise<ApiIntegrationList>} promise - resolved when the list is refreshed.
   */
  async refresh() {
    if (
      isEmpty(this.techAccountIdsToSearch) &&
      feature.isEnabled('temp_devconsole_page_optimization')
    ) {
      return this; // We don't need to make remote calls if there are no techAccountIds to search for
    }

    let adobeioOrgs, apiIntegrationList;

    try {
      // Get the list of adobeio organizations.
      adobeioOrgs = await ApiOrganizationList.get();
    } catch (error) {
      return Promise.reject(error);
    }

    // Find the adobeio organization which maps to the current org.
    this.adobeioOrgId = adobeioOrgs.getAdobeioOrgId({rengaOrgId: this.rengaOrgId});

    try {
      // Get the list of intergrations for the adobeio organization.
      apiIntegrationList = await adobeioConsoleIntegrations.getOrganizationIntegrations({
        orgId: this.adobeioOrgId,
        techAccountIdsToSearch: this.techAccountIdsToSearch,
      });
    } catch (error) {
      log.error(
        `ApiIntegrationList.refresh() failed to load organization integrations. Error: ${error}`
      );
      return Promise.reject(error);
    }

    // Construct the list which is Array<ApiIntegration).
    this.items = apiIntegrationList.map((apiIntegration) =>
      ApiIntegration.apiResponseTransformer(apiIntegration)
    );

    return this;
  }
}

export default ApiIntegrationList;
