import {EVENT_ACTION, dispatchUiEventAnalytics} from '@admin-tribe/acsc';
import {Link} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import chatProvider from 'core/services/chat/chatProvider';
import {ADD_PRODUCT_WORKFLOWS} from 'features/products/components/add-product-modal-wrapper/AddProduct.constants';
import AddProductModalWrapper from 'features/products/components/add-product-modal-wrapper/AddProductModalWrapper';

const AddProductModalLink = ({linkText, items}) => {
  const [showAddProductModal, setShowAddProductModal] = useState(false);

  const openAddProducts = () => {
    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLICK,
      eventName: 'ProductAddMoreLicensesLink',
    });
    setShowAddProductModal(true);
  };

  return (
    <>
      <Link data-test-id="add-product-hyperlink" onPress={openAddProducts}>
        <span>{linkText}</span>
      </Link>
      {showAddProductModal && (
        <AddProductModalWrapper
          chat={chatProvider}
          items={items}
          onClose={() => setShowAddProductModal(false)}
          workflow={ADD_PRODUCT_WORKFLOWS.ADD_LICENSE}
        />
      )}
    </>
  );
};

AddProductModalLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- see wiki above for options
  items: PropTypes.array,
  linkText: PropTypes.string,
};

export default AddProductModalLink;
