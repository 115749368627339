import {PageContext} from '@admin-tribe/acsc';
import {Subpage, useStore} from '@admin-tribe/acsc-ui';
import {PAGE_SIZE_OPTIONS} from '@pandora/react-table-section';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import UserListTableSection from 'common/components/user-list-table-section/UserListTableSection';
import {MemberListContextProvider} from 'common/hooks/member-list-context/MemberListContext';
import ProductUserStore from 'common/stores/user-store/ProductUserStore';
import {processLegacyRoles, saveProductRole} from 'common/utils/member-utils/memberProductUtils';
import LicenseGroupIntegrationList from 'core/products/license-groups/integration-list/LicenseGroupIntegrationList';
import TableProductRolePicker from 'features/products/components/table-product-role-picker/TableProductRolePicker';

/**
 * The subpage for the product profile API credentials which
 * displays in the url as /profiles/profileId/integrations.
 */
const ProductProfileApiCredentialsSubpage = observer(({licenseGroup, pageContext}) => {
  const intl = useIntl();

  const licenseGroupId = pageContext.targetId;
  const productId = pageContext.targetParentId;

  const productUserStore = useStore(
    () =>
      new ProductUserStore({
        immediateLoad: false,
        listClassRef: LicenseGroupIntegrationList,
        listOptions: {
          licenseGroupId,
          productId,
        },
        productId,
      })
  );

  // Finish initializing the productUserStore and then load the table list.
  useEffect(() => {
    const legacyRoles = processLegacyRoles({intl, licenseGroup});

    productUserStore.initProductRoles({
      legacyRoles,
      licenseGroupId: licenseGroup.id,
      showMemberRoles: false,
    });

    productUserStore.load();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on init
  }, []);

  const onRoleChange = ({member, selectedId}) => {
    saveProductRole({
      pageContext,
      productUserStore,
      selectedItems: [member],
      selectedProductRole: selectedId,
    });
  };

  return (
    <Subpage
      isBumpered={false}
      isLoading={productUserStore.isLoading && !productUserStore.currentItems}
    >
      <MemberListContextProvider
        canAddMember={false}
        hasSearch={false}
        memberListDisplay={{
          createdDate: true,
          lastModifiedDate: true,
          productRole: productUserStore.showProductRole,
          viewDetails: true,
        }}
        pageContext={pageContext}
        store={productUserStore}
      >
        <UserListTableSection
          pageSizeOptions={PAGE_SIZE_OPTIONS.MAX_50}
          rolePicker={
            productUserStore.showProductRole && (
              <TableProductRolePicker
                // member prop is added in by MemberListTable
                onRoleChange={onRoleChange}
                roles={productUserStore.roles}
              />
            )
          }
        />
      </MemberListContextProvider>
    </Subpage>
  );
});

ProductProfileApiCredentialsSubpage.propTypes = {
  /**
   * The PageContext object which describes the page target and targetType.
   */
  pageContext: PropTypes.instanceOf(PageContext).isRequired,
};

export default ProductProfileApiCredentialsSubpage;
