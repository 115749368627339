// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import styles from './spotlight-price-info/SpotlightPriceInfo.pcss';

/**
 * Get the price properties for displaying prices.
 */
const getPriceProp = (price, frequency, withStyle) => {
  const priceProps = {
    data: {
      formatString: price.format,
      price: price.amount,
      ...(frequency ? {recurrenceTerm: frequency} : {}),
    },
    options: {
      usePrecision: price.usePrecision,
    },
  };

  if (withStyle) {
    priceProps.options.styles = styles;
  }
  return priceProps;
};

/**
 * From a payload, determine if it is a payload that will have pricing information to display, which can determine
 * some alignment and styling choices.
 * @param {Object} payload
 */
const hasPayloadPricing = (payload) => !!payload.regularPrice && !!payload.priceFrequency;

export {getPriceProp, hasPayloadPricing};
