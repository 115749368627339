import {authentication, feature, log} from '@admin-tribe/acsc';
import {
  InfoItem,
  InfoItemScorecard,
  Page,
  PageActions,
  PageBreadcrumbs,
  PageContent,
  PageDescription,
  PageHeader,
  PageInfoBar,
  PageNav,
  showSuccess,
} from '@admin-tribe/acsc-ui';
import {Button, Item} from '@adobe/react-spectrum';
import {useSharedAssetModel} from '@pandora/data-model-shared-asset';
import {ContentContext, useContentEntry} from '@pandora/react-content-provider';
import {useOrganizationUserHook} from '@pandora/react-data-model-member';
import {SharedProject} from '@pandora/react-data-model-shared-projects';
import {ModalContainer} from '@pandora/react-modal-dialog';
import {
  ASSET_BROWSER_VERSION,
  SHARED_PROJECT_ACTION,
  SHARED_PROJECT_STATE,
  SharedProjectAlertModal,
  SharedProjectAlertModalContentModel,
  SharedProjectTableSection,
  getProjectUrl,
} from '@pandora/react-shared-projects';
import {EN_DASH} from '@pandora/react-table-section';
import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLocation, useParams} from 'react-router-dom';

import API_CONSTANTS from 'common/api/ApiConstants';
import useDocumentTitle from 'common/hooks/useDocumentTitle';
import rootStore from 'core/RootStore';
import {goToSharedProjects} from 'features/storage/routing/navigation-callbacks/navigationCallbacks';
import {getSharedProjectFromId} from 'features/storage/sharedProjects/sharedProjectsUtils';

// The Storage "Project details" page.
const SharedProjectDetailsPage = () => {
  const {projectId} = useParams();

  const {state} = useLocation();

  const env = authentication.getAppEnv?.();
  const project = undefined;
  const projectState = state.projectState;

  const intl = useIntl();
  const {locale} = useContext(ContentContext);

  const {getOrganizationUser} = useOrganizationUserHook();
  const {getSharedAssetModel, loadAssetBrowser} = useSharedAssetModel();
  const modalContent = useContentEntry(SharedProjectAlertModalContentModel);

  const [createdByName, setCreatedByName] = useState();
  const [currentProject, setCurrentProject] = useState(project);
  const [isBumpered, setIsBumpered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invitedUsersCount, setInvitedUsersCount] = useState();
  const [memberCount, setMemberCount] = useState();
  const [shouldFetchProject, setShouldFetchProject] = useState(true);
  const [projectAction, setProjectAction] = useState();
  const [projectDescription, setProjectDescription] = useState();
  const [projectName, setProjectName] = useState('');

  const getActionButton = (action) => (
    <Button onPress={() => setProjectAction(action)} variant="secondary">
      {intl.formatMessage({id: `storage.sharedProjects.details.button.${action}`})}
    </Button>
  );

  const onSuccess = (editedProject) => {
    setProjectAction(undefined);
    showSuccess(intl.formatMessage({id: `pandora.sharedProjectListTableSection.${projectAction}`}));

    if (editedProject) {
      setProjectDescription(editedProject.description);
      setProjectName(editedProject.name);

      setCurrentProject({
        ...currentProject,
        description: editedProject.description,
        name: editedProject.name,
      });
    } else {
      goToSharedProjects();
    }
  };

  const onUpdateSharedProject = (action, updatedUserIds) => {
    if (action === SHARED_PROJECT_ACTION.INVITE_USER) {
      setMemberCount(memberCount + updatedUserIds.length);
    } else if (action === SHARED_PROJECT_ACTION.REMOVE_USER) {
      setMemberCount(memberCount - updatedUserIds.length);
    }
  };

  // Get project given project ID, in the case of deeplinking to the detail page from a URL parameter
  useEffect(() => {
    const getSharedProjectData = async () => {
      setIsLoading(true);
      try {
        const sharedAssetModel = await getSharedAssetModel({
          applicationId: 'AdminConsole',
          applicationLocation: 'AdminConsole/Projects',
          clientId: 'ONESIE1',
          ...(feature.isEnabled('admin_mode_org_id') && {
            orgId: rootStore.organizationStore.activeOrgId,
          }),
        });
        const foundProject =
          project?.team ||
          (await getSharedProjectFromId(sharedAssetModel, projectId, projectState));

        if (foundProject) {
          // foundProject is really of type Team, so convert to project
          const convertedProject = new SharedProject({
            getOrganizationUser,
            includeDescription: true,
            includeInvitedUsers: true,
            logger: log,
            team: foundProject,
          });
          await convertedProject.build();
          await loadAssetBrowser({
            ...(feature.isEnabled('admin_mode_org_id') && {
              orgId: rootStore.organizationStore.activeOrgId,
            }),
            projectUrl: getProjectUrl(env, rootStore.organizationStore.activeOrgId),
            version: ASSET_BROWSER_VERSION,
          });

          setCurrentProject(convertedProject);
          setInvitedUsersCount(convertedProject.invitedUsers?.length);
          setMemberCount(convertedProject.users?.length);
          setProjectDescription(convertedProject.description);
          setProjectName(convertedProject.name);
          setCreatedByName(convertedProject.createdBy?.getDisplayNameOrEmail(locale));
        } else {
          log.error(`Error: No projects present`);

          setIsBumpered(true);
        }
      } catch (error) {
        log.error(`Error getting project data: ${error}`);

        setIsBumpered(true);
      } finally {
        setIsLoading(false);
        setShouldFetchProject(false);
      }
    };

    if (shouldFetchProject) {
      getSharedProjectData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on load
  }, [shouldFetchProject]);

  useDocumentTitle({defaultTitle: projectName});

  const getTitleString = () => intl.formatMessage({id: 'storage.sharedProjects.title'});

  return (
    <Page data-testid="shared-project-details-page" isBumpered={isBumpered} isLoading={isLoading}>
      <PageBreadcrumbs
        isDisabled={false}
        onAction={() => goToSharedProjects({state: currentProject.state})}
      >
        <Item key="projects">{getTitleString()}</Item>
        <Item key="details">{projectName}</Item>
      </PageBreadcrumbs>
      <PageHeader title={projectName} />
      {projectDescription && <PageDescription>{projectDescription}</PageDescription>}
      <PageActions>
        {currentProject?.state === SHARED_PROJECT_STATE.DISCARDED ? (
          <>
            {getActionButton(SHARED_PROJECT_ACTION.RESTORE)}
            {getActionButton(SHARED_PROJECT_ACTION.REMOVE)}
          </>
        ) : (
          <>
            {getActionButton(SHARED_PROJECT_ACTION.EDIT)}
            {getActionButton(SHARED_PROJECT_ACTION.DELETE)}
          </>
        )}
      </PageActions>
      <PageInfoBar>
        <InfoItemScorecard
          label={intl.formatMessage({id: 'storage.sharedProjects.scorecard.users'})}
          value={memberCount}
        />
        <InfoItemScorecard
          label={intl.formatMessage({id: 'storage.sharedProjects.scorecard.pendingInvitations'})}
          value={invitedUsersCount}
        />
        <InfoItem
          label={intl.formatMessage({id: 'storage.sharedProjects.scorecard.createdBy'})}
          value={createdByName ?? EN_DASH}
        />
      </PageInfoBar>
      <PageNav />
      <PageContent>
        {projectAction && (
          <ModalContainer>
            <SharedProjectAlertModal
              action={projectAction}
              content={modalContent}
              onCancel={() => setProjectAction(undefined)}
              onSuccess={onSuccess}
              sharedProject={currentProject}
            />
          </ModalContainer>
        )}
        {currentProject && (
          <SharedProjectTableSection
            clientId={API_CONSTANTS.CLIENT_ID}
            onCloseInviteModal={() => setShouldFetchProject(true)}
            onUpdateSharedProject={onUpdateSharedProject}
            sharedProject={currentProject}
          />
        )}
      </PageContent>
    </Page>
  );
};

export default SharedProjectDetailsPage;
