import {
  CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY,
  CONTRACT_EXPIRATION_PHASE,
  ConsumableSummarizationList,
  PRODUCT_DELEGATION_TARGET,
  Product,
  feature,
  getExpirationPhaseForAllLicenseTuples,
} from '@admin-tribe/acsc';
import pickBy from 'lodash/pickBy';

import {getDevConsoleEndpointUrl} from 'common/utils/devConsoleUtils';
import rootStore from 'core/RootStore';
import {
  canAssignUser,
  canBuyTrialProduct,
  canPurchaseLicenses,
} from 'core/products/access/productAccess';
import trialHelper from 'core/products/trial-helper/trialHelper';
import {isCustomModelProduct} from 'core/products/utils/consumableProductUtils';

import {PRODUCT_TABLE_ACTION_VARIANT} from './ProductSummaryListConstants';

/**
 * @typedef {Object} StatusContent
 * @property {String} [contractStatus] - The status of the contract
 * @property {String} [expirationPhase] - The expiration phase based on license
 *     compliance symptoms and the contract status.
 * @property {Boolean} shouldShowStatusContent - Flag indicating whether the
 *     contract the product belongs to is ETLA or allocation AND the provided
 *     argument is a Product instance.
 */

/**
 * @description Helper method to obtain the array of contract names to be provided to the ProductNameCell
 * The ProductNameCell will only display the first contract name from the list even if there are multiple contracts
 * @param {Array<Object>} Array of objects wrapping contract names returned by the ProductSummaryList
 * @returns {Array<String> | undefined} returns an array of contract names or undefined
 */
function getProductContractNames(contracts) {
  const contractList = rootStore.organizationStore.contractList;

  return contractList.items.length > 1 && contracts
    ? contracts.map((contract) => contract.name)
    : undefined;
}

/**
 * @description Method to obtain the action content to display in the table.
 *
 * @returns {Array<Promise<Object>>} Promise that will resolve to an object that
 *     contains the variant and org id, or will resolve to undefined. Will
 *     return the first available option.
 */
function getProductTableActionContent(productOrProductGroup, orgId) {
  // Anything encountered that is not a Product should be considered a
  // ProductGroupProductList or one of its child classes.
  const isGroup = !(productOrProductGroup instanceof Product);
  const targetProduct = isGroup
    ? // Only taking the first entry because if we're dealing with a product
      // group the relevant actions should be the same for any of the items so
      // we'll look at the first item for determining the actions.
      productOrProductGroup.items[0]
    : productOrProductGroup;

  const actionContentArr = [];
  configureLinkOutAction(actionContentArr, targetProduct);
  configureDeveloperConsoleAction(actionContentArr, targetProduct);
  configureAddUsersAction(actionContentArr, isGroup, targetProduct, orgId);
  configureBuyTrialProductAction(actionContentArr, targetProduct);
  configurePackageAction(actionContentArr, targetProduct);
  configureBuyLicensesAction(actionContentArr, targetProduct, orgId);

  return {
    actionPromise: fetchConsumablesForProduct(targetProduct, orgId),
    actions: actionContentArr,
  };
}

/**
 * @description Add the link-out action if appropriate.
 */
function configureLinkOutAction(actionContentArr, targetProduct) {
  const productLink = targetProduct.links?.product_admin?.[0];
  if (productLink) {
    actionContentArr.push({
      variant: PRODUCT_TABLE_ACTION_VARIANT.LINK_OUT,
    });
  }
}

/**
 * @description Add the developer-console action if appropriate.
 */
function configureDeveloperConsoleAction(actionContentArr, targetProduct) {
  const url = getDevConsoleEndpointUrl();
  if (url && targetProduct.isDelegatableToType(PRODUCT_DELEGATION_TARGET.API_KEY)) {
    actionContentArr.push({
      variant: PRODUCT_TABLE_ACTION_VARIANT.GO_TO_DEVELOPER_CONSOLE,
    });
  }
}

/**
 * @description Add the add-users action if appropriate.
 */
function configureAddUsersAction(actionContentArr, isGroup, targetProduct, orgId) {
  if (!isGroup && canAssignUser(targetProduct)) {
    actionContentArr.push({
      orgId,
      variant: PRODUCT_TABLE_ACTION_VARIANT.ADD_USERS,
    });
  }
}

/**
 * @description Add the buy-licenses action if appropriate.
 */
function configureBuyLicensesAction(actionContentArr, targetProduct, orgId) {
  if (feature.isEnabled('temp_cta_direct_link_buy_license_demo')) {
    if (canPurchaseLicenses(targetProduct)) {
      actionContentArr.push({
        orgId,
        variant: PRODUCT_TABLE_ACTION_VARIANT.BUY_LICENSES,
      });
    }
  }
}

/**
 * @description Add the buy-product action if appropriate.
 */
function configureBuyTrialProductAction(actionContentArr, targetProduct) {
  if (canBuyTrialProduct(targetProduct)) {
    actionContentArr.push({
      variant: PRODUCT_TABLE_ACTION_VARIANT.BUY_PRODUCT,
    });
  }
}

/**
 * @description Add the packages action if appropriate.
 */
function configurePackageAction(actionContentArr, targetProduct) {
  if (targetProduct.isFeatureRestrictedLicense() && targetProduct.isAdministerable()) {
    actionContentArr.push({
      variant: PRODUCT_TABLE_ACTION_VARIANT.GO_TO_PACKAGES,
    });
  }
}

/**
 * @description Method to obtain the status object content that will show the
 *     contractExpirationStatus widget for products belonging to ETLA or
 *     allocation contracts only.
 * @param {Arrray<Contract>} contracts - Array of contracts whose first
 *     entry, if it exists, will have its properties checked.
 *     While the expectation is that every product is attached to at least one contract,
 *     this does not always seem to be the case, so code for the case of 0 contracts.
 * @param {Product | ProductGroupProductList} productOrProductGroup - Product or
 *     product group product list whose tuples will be checked for
 *     determining contract expiration phase. In practice because this is only
 *     applicable for ETLA and allocation contract types FOR NOW....we will
 *     omit the expiration phase and contract status for a
 *     productGroupProductList because at the time of this writing that should
 *     not exist on an ETLA or allocation contract (Stock credit packs are seen
 *     on VIP or Team Direct, and DX products with instances appear on DX
 *     contracts only), but it could exist in the future and if that comes into
 *     existence this block should be updated accordingly.
 * @returns {StatusContent} Object content used for populating the status column
 *     of the product overview table.
 */
function getStatusContent(contracts, productOrProductGroup) {
  // Just looking at the first contract because for ETLA or allocation there
  // should only be one matching contract.
  const contract = contracts.length > 0 ? contracts[0] : undefined;

  let shouldShowStatusContent = contract
    ? (contract.isBuyingProgramETLA() || contract.isModelAllocation()) &&
      productOrProductGroup instanceof Product
    : false;
  let expirationPhase;
  if (shouldShowStatusContent) {
    expirationPhase = getExpirationPhaseForAllLicenseTuples(contract.status, productOrProductGroup);
    if (expirationPhase === CONTRACT_EXPIRATION_PHASE.NORMAL) {
      // If the expiration phase is normal we don't want to show it.
      shouldShowStatusContent = false;
    }
  }
  return pickBy(
    {
      contractStatus: shouldShowStatusContent ? contract.status : undefined,
      expirationPhase: shouldShowStatusContent ? expirationPhase : undefined,
      shouldShowStatusContent,
    },
    (property) => property !== undefined
  );
}

/**
 * @description Method to obtain the tag content for a given product or product
 *     group product list.
 * @param {Object} options - Top level wrapper object.
 * @param {Array<Contract>} options.contracts - Array of contracts for the product.
 *     While the expectation is that every product is attached to at least one contract,
 *     products can be standalone as well without a contract (for ex: legacy_dx products),
 *     that's why we have to check if contract exists.
 *     Also as part of multiple ETLA contracts feature, we can have same product
 *     attached to multiple contracts so checking for multiple contracts.
 * @param {Product | ProductGroupProductList} options.productOrProductGroup -
 *     Product or ProductGroupProductList that will be checked for tag content.
 * @returns {Array<Object>} Array of tags each containing a label.
 */
function getTagContent({contracts, productOrProductGroup}) {
  const tags = [];

  const targetProduct =
    productOrProductGroup instanceof Product
      ? productOrProductGroup
      : productOrProductGroup.items[0];
  const shouldShowApiAvailableTag = targetProduct.isDelegatableToType(
    PRODUCT_DELEGATION_TARGET.API_KEY
  );
  if (shouldShowApiAvailableTag) {
    tags.push({
      label: 'products.allProducts.table.tags.apiAvailable',
    });
  }

  const contract = contracts.length > 0 ? contracts[0] : undefined;
  if (contract?.isModelAllocation()) {
    tags.push({
      label: 'products.allProducts.table.tags.allocation',
    });
  }

  // Products can be trial products or part of a trial contract.
  const hasTrialETLAContract =
    feature.isEnabled('temp_pa2881_multiple_contracts') &&
    contracts.length > 0 &&
    contracts.some((selectedContract) => trialHelper.isTrialETLAContract(selectedContract));

  if (trialHelper.isTrialProduct(targetProduct) || hasTrialETLAContract) {
    tags.push({
      label: 'products.allProducts.table.tags.trial',
    });
  }

  return tags;
}

/////////

async function fetchConsumablesForProduct(product, orgId) {
  if (feature.isEnabled('temp_custom_model_scorecards') && isCustomModelProduct(product)) {
    return undefined;
  }
  const summarizationList = await ConsumableSummarizationList.get({
    include_usage: false,
    organization_id: orgId,
    summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
  });

  const consumables = summarizationList.getConsumablesForSummaryId(product.id, {
    summarize_by: CONSUMABLE_SUMMARIZATION_SUMMARIZE_BY.LICENSE_ID,
  });

  if (
    consumables.length > 0 &&
    consumables.every((consumable) => consumable.consumedQuantity === 0)
  ) {
    return {
      variant: PRODUCT_TABLE_ACTION_VARIANT.SET_UP_SDL,
    };
  }
  return undefined;
}

export {getProductContractNames, getProductTableActionContent, getStatusContent, getTagContent};
