// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {LicenseGroupList, dispatchUiEventAnalytics} from '@admin-tribe/acsc';
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
  View,
} from '@adobe/react-spectrum';
import {useCreateRule} from '@pandora/data-model-acrs';
import SafeHtml from '@pandora/react-safe-html';
import {error, success} from '@react/react-spectrum/Toast';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import AutoAssignRulesCache from 'core/services/product/AutoAssignRulesCache';

import CopyUrlModal from './CopyUrlModal';

/**
 * Auto Assignment Rules Modal dialog to generate rules for an org.
 */
const AutoAssignmentRulesModalDialog = ({content, product, onClose, onCloseWithoutDismiss}) => {
  const intl = useIntl();
  const [defaultProfile, setDefaultProfile] = useState(null);
  const [showCopyUrlModal, setShowCopyUrlModal] = useState(false);
  const [isCreatingRule, setIsCreatingRule] = useState(false);
  const orgId = rootStore.organizationStore?.activeOrgId;
  const loadingDefaultProfile = useCallback(async () => {
    const licenseGroup = await LicenseGroupList.get({
      orgId,
      product,
    });
    setDefaultProfile(licenseGroup.items[0]);
  }, [orgId, product]);

  // Load the default profile
  useEffect(() => {
    loadingDefaultProfile();
  }, [loadingDefaultProfile, product]);

  const {
    data: savedRuleDetails,
    error: saveError,
    mutate: onSaveMutate,
  } = useCreateRule({
    orgId,
    ruleId: null, // Create a new rule
  });

  // Showing success message when the rule is saved
  useEffect(() => {
    const refreshCache = async () => {
      await AutoAssignRulesCache.get().refresh();
    };

    if (savedRuleDetails) {
      success(
        intl.formatMessage(
          {id: 'pandora.autoProductAssignmentModalContent.saveSuccessMessage'},
          {product: product.longName}
        )
      );
      setShowCopyUrlModal(true);
      refreshCache();
    } // eslint-disable-next-line react-hooks/exhaustive-deps -- savedRuleDetails is the only dependency
  }, [savedRuleDetails]);

  // Showing error message when the rule is not saved
  useEffect(() => {
    if (saveError) {
      error(intl.formatMessage({id: 'pandora.autoProductAssignmentModalContent.saveErrorMessage'}));
      setShowCopyUrlModal(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps -- saveError is the only dependency
  }, [saveError]);

  const onCreate = () => {
    const requestBody = {
      label: product.id,
      licenseId: product.id,
      productProfile: defaultProfile.id,
      status: content.ruleStatus,
      triggers: content.access,
      userScope: content.availableTo,
    };
    // Call the API to create the rule
    setIsCreatingRule(true);
    onSaveMutate(requestBody);
  };

  const invokeCancelWithAnalytics = (fn) => {
    fn();
    dispatchUiEventAnalytics({
      eventAction: 'cancel',
      eventName: 'autoassignment:jitrule:createrule',
    });
  };

  const invokeCreateWithAnalytics = (fn) => {
    fn();
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'autoassignment:jitrule:createrule',
    });
  };

  const renderDialogComponent = useMemo(
    () =>
      defaultProfile &&
      product && (
        <Dialog analyticsModalId="one-click-assignment" dataTestId="one-click-assignment-modal">
          <Heading data-testid="auto-assignment-rules-heading">
            <Flex alignItems="center" gap="size-100">
              <Text>{content.heading}</Text>
            </Flex>
          </Heading>
          <Divider />
          <Content
            data-testid={`auto-assignment-rules-${content.productFamily}-content`}
            marginTop="-15px"
          >
            <Flex direction="column">
              <View
                data-testid={`auto-assignment-rules-${content.productFamily}-description`}
                marginTop="-25px"
              >
                <SafeHtml
                  html={content.description?.html}
                  options={{
                    ADD_ATTR: ['target'],
                  }}
                />
              </View>
              <Flex alignItems="start" direction="row">
                <View
                  data-testid={`auto-assignment-rules-${content.productFamily}-icon`}
                  paddingEnd="25px"
                >
                  <Image
                    alt={content.imageAlt || ''}
                    data-testid={`auto-assignment-rules-${content.productFamily}-icon-image`}
                    height="auto"
                    objectFit="cover"
                    src={content.imageUrl}
                    width="size-700"
                  />
                </View>
                <Flex
                  alignItems="start"
                  data-testid={`auto-assignment-rules-${content.productFamily}-profile`}
                  direction="column"
                >
                  <Text>
                    {intl.formatMessage(
                      {id: 'product.oneClickAssignmentModal.product'},
                      {product: <strong>{product.longName}</strong>}
                    )}
                  </Text>
                  <Text>
                    {intl.formatMessage(
                      {id: 'product.oneClickAssignmentModal.profile'},
                      {profile: <strong>{defaultProfile.name}</strong>}
                    )}
                  </Text>
                  <Text>
                    {intl.formatMessage(
                      {id: 'product.oneClickAssignmentModal.availableTo'},
                      {availableTo: <strong>{content.availableTo}</strong>}
                    )}
                  </Text>
                  <Text>
                    {intl.formatMessage(
                      {id: 'product.oneClickAssignmentModal.access'},
                      {access: <strong>{content.access}</strong>}
                    )}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Content>

          <ButtonGroup>
            <Button
              data-testid={`auto-assignment-rules-${content.productFamily}-cancel`}
              onPress={() => invokeCancelWithAnalytics(onCloseWithoutDismiss)}
              variant="secondary"
            >
              {content.secondaryCTALabel}
            </Button>
            <Button
              data-testid={`auto-assignment-rules-${content.productFamily}-confirm`}
              isDisabled={isCreatingRule}
              onPress={() => invokeCreateWithAnalytics(onCreate)}
              variant="accent"
            >
              {content.primaryCTALabel}
            </Button>
          </ButtonGroup>
        </Dialog>
      ), // eslint-disable-next-line react-hooks/exhaustive-deps -- defaultProfile, product, content, isCreatingRule are the only dependencies
    [defaultProfile, product, content, isCreatingRule]
  );

  const renderCopyUrlModal = useMemo(
    () => <CopyUrlModal onDismiss={onClose} urlText={savedRuleDetails?.requestURL?.browseURL} />, // eslint-disable-next-line react-hooks/exhaustive-deps -- savedRuleDetails is the only dependency
    [savedRuleDetails]
  );

  return showCopyUrlModal ? renderCopyUrlModal : renderDialogComponent;
};

AutoAssignmentRulesModalDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- method to pass to has too general an interface
  content: PropTypes.object,
  onClose: PropTypes.func,
  onCloseWithoutDismiss: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types -- method to pass to has too general an interface
  product: PropTypes.object,
};

export default AutoAssignmentRulesModalDialog;
