/**
 * @description Redirect the current user session to the main home page for successful approval.
 *
 * @param {String} orgId The org of the user
 */
const redirectToHomePage = (orgId) => {
  if (typeof window !== 'undefined') {
    window.location.href = `/${orgId}`;
  }
};

/**
 * @description Redirect the current user session to the main product access review page for any
 *   error while processing approval.
 * @param {String} orgId The org of the user
 * @param {String} userId The user id of the current user session.
 * @param {String} queryParam The query parameter to be passed to the review page.
 * @param {String} queryParamValue The value of the query parameter.
 */
const redirectToProductReviewPage = (orgId, userId, queryParam, queryParamValue) => {
  if (typeof window !== 'undefined') {
    let url = `/${orgId}/products/requests`;
    if (userId !== null && userId !== undefined) {
      url += `/${userId}`;
    }

    if (queryParam && queryParamValue) {
      url += `?${queryParam}=${queryParamValue}`;
    }
    window.location.href = url;
  }
};

export {redirectToHomePage, redirectToProductReviewPage};
