// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {feature} from '@admin-tribe/acsc';
import {Flex, View} from '@adobe/react-spectrum';
import {DividerPlacement, TitledSection} from '@pandora/react-titled-section';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import SophiaPromoRightRailPanel from 'common/components/sophia/sophia-promo/SophiaPromoRightRailPanel';
import OverviewPod from 'features/overview/shell/overview-pod/OverviewPod';

// eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- disabled for resolving merge conflicts
// istanbul ignore file
const SophiaRightRailPromoSection = ({isHidden}) => {
  const intl = useIntl();
  const headingId = useId();
  const [isDismissed, setIsDismissed] = React.useState(false);
  const {payload} = useJsonPayload();

  if (!payload) {
    return null;
  }

  const hideContent = {...{base: true, L: true, M: true, S: true, XS: true}, ...isHidden};

  const dismissView = payload.dismissible ? () => setIsDismissed(false) : null;

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- disabled for resolving merge conflicts
  // istanbul ignore next
  const useAemForSectionTitle =
    feature.isEnabled('temp_tno_promo_panel_aem_section_title') && !!payload.sectionTitle;
  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- disabled for resolving merge conflicts
  // istanbul ignore next
  const sectionTitle = useAemForSectionTitle
    ? payload.sectionTitle
    : intl.formatMessage({id: 'overview.promotions.pod.title'});

  return (
    <View isHidden={isDismissed}>
      <OverviewPod data-testid="sophia-right-rail-merchandising-section" isHidden={hideContent}>
        <TitledSection
          dividerPlacement={DividerPlacement.NONE}
          headingLevel={2}
          id={headingId}
          title={sectionTitle}
        >
          <Flex
            alignItems="baseline"
            data-testid="sophia-right-rail-merchandising-space"
            gap="size-300"
            marginTop="size-150"
            wrap
          >
            <SophiaPromoRightRailPanel onDismiss={dismissView} />
          </Flex>
        </TitledSection>
      </OverviewPod>
    </View>
  );
};

SophiaRightRailPromoSection.propTypes = {
  /**
   * Responsive props instructing merchandising section how to show/hide at
   * various display sizes. Optional, default is to show all the time
   */
  isHidden: PropTypes.shape({
    base: PropTypes.bool,
    L: PropTypes.bool,
    M: PropTypes.bool,
    S: PropTypes.bool,
  }),
};

export default SophiaRightRailPromoSection;
