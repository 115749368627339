import {configStore, log} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';

const ConfigurationContext = createContext();

/**
 * Provides Configuration data to its children components.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children components.
 * @returns {React.ReactNode} The wrapped children components.
 */

const ConfigurationProvider = ({children}) => {
  const [configuration, setConfiguration] = useState(null);
  const [configurationLoaded, setConfigurationLoaded] = useState(false);
  const handleError = useErrorHandler();
  // Fetch data from the Configuration file
  useEffect(() => {
    (async () => {
      try {
        const timestamp = Date.now();
        const noCacheConfigFile = `/configuration.95316e01e9.json?timestamp=${timestamp}`;
        const response = await fetch(noCacheConfigFile);
        const jsonData = await response.json();
        configStore.initialize(jsonData);
        setConfiguration(jsonData);
        setConfigurationLoaded(true);
      } catch (error) {
        log.error('Error fetching configuration data:', error);
        setConfigurationLoaded(false);
        handleError(error);
      }
    })();
  }, [handleError]);
  const contextValue = useMemo(
    () => ({configuration, configurationLoaded}),
    [configuration, configurationLoaded]
  );

  return (
    <ConfigurationContext.Provider value={contextValue}>{children}</ConfigurationContext.Provider>
  );
};

ConfigurationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useConfiguration = () => useContext(ConfigurationContext);

export {ConfigurationProvider, useConfiguration};
