import {ORGANIZATION_MARKET_SEGMENT} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import {isVipContract} from 'features/account/contract-banner/ContractBanner.utils';
import {GLOBAL_BANNER_TYPES} from 'features/global-banner/GlobalBannerConstants';

/**
 * @description Method called by the main boot service to generate the global banner for VIP
 */
function notifyVipContractSunsetBanner() {
  const contractList = rootStore.organizationStore.contractList;
  const activeOrg = rootStore.organizationStore.activeOrg;

  const hasVIPContract = contractList.items.some((contract) => isVipContract(contract));

  if (hasVIPContract && activeOrg.marketSegment === ORGANIZATION_MARKET_SEGMENT.COMMERCIAL) {
    const banner = {
      message: 'globalBanner.messages.vipContractSunset',
      messageArgs: {
        goUrlName: 'adobe-buying-program-update',
      },
      type: GLOBAL_BANNER_TYPES.WARNING,
    };

    rootStore.organizationStore.globalBannerStore.add(banner);
  }
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyVipContractSunsetBanner};
