/* eslint-disable @admin-tribe/admin-tribe/one-component-file -- cannot split the function further*/
import {feature} from '@admin-tribe/acsc';
import {ModalContainer} from '@admin-tribe/acsc-ui';
import {ActionButton, Button, Flex, Heading, Image, Link, Text, View} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {useId} from '@react-aria/utils';
import {useProvider} from '@react-spectrum/provider';
import CloseIcon from '@spectrum-icons/workflow/Close';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';

import HvaCardClass from 'features/overview/hva-cards/HvaCard';

// Hva Card button. Handles opening modal with React.Fragment, or a custom callback with onCTA
const HvaCardButton = ({card, isSmallScreenWidth, onCTA, onDismiss}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ctaButtonId = useId(card.id);
  const Container = card.modalConfig?.includeModalContainer ? ModalContainer : React.Fragment;
  const closeModal = () => {
    setIsOpen(false);

    if (card.dismissOnCTA) {
      onDismiss(card);
    }
  };

  const closeWithoutDismiss = () => {
    setIsOpen(false);
  };

  const variant = feature.isEnabled('temp_tno_new_quick_link_section') ? 'primary' : 'accent';
  return (
    <View flexShrink="0">
      <Flex
        flexBasis={!isSmallScreenWidth && '20%'}
        justifyContent="end"
        marginTop={isSmallScreenWidth && 'size-100'}
      >
        {card.modalConfig.Component ? (
          <>
            <Button
              id={`${ctaButtonId}-btn`}
              onPress={() => {
                card.modalConfig.props?.callBeforeOpeningModal?.();
                setIsOpen(true);
              }}
              variant={variant}
            >
              {card.ctaLabel}
            </Button>
            {isOpen && (
              <Container>
                <card.modalConfig.Component
                  {...card.modalConfig.props}
                  isOpen={card.modalConfig?.props?.includeIsOpen ? isOpen : undefined}
                  {...{
                    [card.modalConfig.closeProp]: () => closeModal(),
                  }}
                  {...{
                    [card.modalConfig.successProp]: () => closeModal(),
                  }}
                  {...{
                    [card.modalConfig.closeWithoutDismissProp]: () => closeWithoutDismiss(),
                  }}
                />
              </Container>
            )}
          </>
        ) : (
          <Button id={`${ctaButtonId}-btn`} onPress={onCTA} variant={variant}>
            {card.ctaLabel}
          </Button>
        )}
      </Flex>
    </View>
  );
};
/* eslint-enable @admin-tribe/admin-tribe/one-component-file -- attempt to minimize component*/
/* eslint-disable complexity -- complex logic for responsive design */
// renders a Hva (High Value Action) card
const HvaCard = ({card, onDismiss}) => {
  const intl = useIntl();
  const {breakpoints} = useProvider();

  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- callback runs in browser
  const isSmallScreenWidth = window?.innerWidth < breakpoints?.S;

  const onCTA = () => {
    card.onCTA?.();

    if (card.dismissOnCTA) {
      onDismiss(card);
    }
  };

  const cardHasIcon = !!(card.icon.Component || card.icon.src);

  const getDescription = () => {
    const goUrl = card.goUrl
      ? (linkText) => (
          <GoUrl name={card.goUrl} noWrap={false}>
            {linkText}
          </GoUrl>
        )
      : undefined;

    const link = card.learnMoreHref
      ? (str) => (
          <Link>
            <a href={card.learnMoreHref} rel="noreferrer" target="_blank">
              {str}
            </a>
          </Link>
        )
      : undefined;

    const gainsightTour = card.gainsightTour
      ? (str) => <Link id={card.gainsightTour}>{str[0]}</Link>
      : undefined;

    const values = {
      ...card.intlValues,
      gainsightTour,
      goUrl,
      link,
    };

    // pass in a fake id, so that the defaultMessage is used as fallback,
    // which is really just our already-translated sophia string.
    // We're just using intl as a tool to inject links
    return intl.formatMessage({defaultMessage: card.description, id: 'hva.description'}, values);
  };

  return (
    <View
      backgroundColor="gray-50"
      borderColor="gray-200"
      borderRadius="medium"
      borderWidth="thin"
      padding="size-75"
    >
      <Flex
        height="100%"
        marginEnd={!card.isDismissible && 'size-200'}
        marginStart="size-200"
        minHeight="size-1200"
      >
        <Flex alignItems="center" flexGrow={1} gap="size-100">
          {!isSmallScreenWidth && cardHasIcon && (
            <Flex
              alignContent="center"
              alignItems="center"
              flexBasis="130px"
              flexGrow={0}
              height="size-1200"
              justifyContent="center"
              marginEnd="size-100"
            >
              {card.icon.src && (
                <Image
                  alt={card.header}
                  objectFit="contain"
                  src={card.icon.src}
                  UNSAFE_style={card.icon.style}
                />
              )}
              {card.icon.Component && <card.icon.Component {...card.icon.props} />}
            </Flex>
          )}
          <Flex direction="column" flexGrow={1} marginY={isSmallScreenWidth && 'size-125'}>
            <Heading level={3} margin={0}>
              {card.header}
            </Heading>
            <Text>{getDescription()}</Text>
            {isSmallScreenWidth && card.ctaLabel && (
              <HvaCardButton
                card={card}
                isSmallScreenWidth={isSmallScreenWidth}
                onCTA={onCTA}
                onDismiss={onDismiss}
              />
            )}
          </Flex>
          {!isSmallScreenWidth && card.ctaLabel && (
            <HvaCardButton
              card={card}
              isSmallScreenWidth={isSmallScreenWidth}
              onCTA={onCTA}
              onDismiss={onDismiss}
            />
          )}
        </Flex>
        {card.isDismissible && (
          <ActionButton
            aria-label={intl.formatMessage({id: 'overview.highValueActionArea.dismiss'})}
            isQuiet
            onPress={() => onDismiss(card)}
          >
            <CloseIcon />
          </ActionButton>
        )}
      </Flex>
    </View>
  );
};
/* eslint-enable complexity -- complex logic for responsive design */

HvaCardButton.propTypes = {
  /**
   * The Hva card
   */
  card: PropTypes.instanceOf(HvaCardClass).isRequired,
  /**
   * Whether the viewport is small. When true, HvaCard will wrap the button. When false, HvaCard will render the button in-line.
   */
  isSmallScreenWidth: PropTypes.bool.isRequired,
  /**
   * CTA onPress callback
   */
  onCTA: PropTypes.func.isRequired,
  /**
   * Callback to dismiss the card
   */
  onDismiss: PropTypes.func.isRequired,
};

HvaCard.propTypes = {
  /**
   * The Hva card to render
   */
  card: PropTypes.instanceOf(HvaCardClass).isRequired,
  /**
   * Callback when the Hva card is dismissed
   */
  onDismiss: PropTypes.func,
};

export default HvaCard;
