import {showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {
  PREVIOUSLY_APPROVED_TOAST_ID,
  RA_TOAST_QUERY_PARAM,
  SUCCESS_TOAST_ID,
} from 'features/approve-product/utils/productApproveConstants';
/**
 * Display success message in case of product approval via email
 */
const ProductApprovalSuccessMessage = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  /**
   * Check value of queryParam request_access_approval_result to display product approval success
   * message.
   * setTimeout is used to introduce delay before removing the queryParam from URL since the navigate reloads
   * the react component and the toast message needs to be rendered for a while before removing the queryParam.
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const successMessage = queryParams.get(RA_TOAST_QUERY_PARAM);

    if (successMessage) {
      if (successMessage === SUCCESS_TOAST_ID) {
        showSuccessToast(intl.formatMessage({id: 'common.requestAccess.requestApproved'}));
      }

      if (successMessage === PREVIOUSLY_APPROVED_TOAST_ID) {
        showSuccessToast(
          intl.formatMessage({id: 'common.requestAccess.requestPreviouslyApproved'})
        );
      }

      setTimeout(() => {
        queryParams.delete(RA_TOAST_QUERY_PARAM);
        navigate({search: queryParams.toString()});
      }, 500);
    }
  }, [intl, location, navigate]);
  return null;
};

export default ProductApprovalSuccessMessage;
